import "jquery.easing";
import { isMobile } from "./isMobile";

/*
 *
 * ----------------------------------------------- */
jQuery(function ($) {
  var $btn = $(".page-top"); // page top btn
  var $btnContainer = $(".footer"); // page top btn container

  var btnFixedPosition = 0;

  if (isMobile()) {
    btnFixedPosition = 10;
  } else {
    btnFixedPosition = 40;
  }

  var yTop = 0; // トップの縦位置（ピクセル）
  var pxInBackToTop = 180 + yTop; // どれくらい下へスクロールしたらトップへ戻るボタンを出すか（ピクセル）
  var msInBTTBtn = 300; // トップへ戻るボタンが出てくるアニメーションの時間（ミリ秒）
  var msBTTScroll = 800; // トップへ戻るスクロールアニメーションの時間（ミリ秒）

  // トップへ戻るボタン
  var yDefBttBtn = parseInt($btn.css("bottom"));
  var btnDefaultY = "-100px";
  $btn.css("bottom", btnDefaultY);

  var isInBttBtn = false;
  var isOverFooter = false;

  $(window).scroll(setBttPos).resize(setBttPos);

  function setBttPos() {
    var y = $(window).scrollTop();

    if (y > pxInBackToTop) {
      if (!isInBttBtn) {
        isInBttBtn = true;

        $btn.stop().animate(
          {
            bottom: btnFixedPosition + "px",
          },
          msInBTTBtn,
          "easeOutBack"
        );
      }
    } else {
      if (isInBttBtn) {
        isInBttBtn = false;

        $btn.stop().animate(
          {
            bottom: btnDefaultY,
          },
          msInBTTBtn,
          "easeInBack"
        );
      }
    }

    // トップへ戻るボタンの位置をフッターの上で止める
    if ($btnContainer.length > 0) {
      var yf =
        $btnContainer.offset().top + yDefBttBtn - y - 2 - $(window).height();

      if (yf < -btnFixedPosition) {
        isOverFooter = true;
        $btn.stop().css("bottom", yDefBttBtn - yf + "px");
      } else if (isOverFooter) {
        isOverFooter = false;
        $btn.stop().css("bottom", btnFixedPosition + "px");
      }
    }
  }

  // move to page top
  $btn.on("click", function (e) {
    $("html, body").stop().animate(
      {
        scrollTop: yTop,
      },
      msBTTScroll,
      "easeOutQuint"
    );

    e.preventDefault();
  });
});
