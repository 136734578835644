import "imagemapster";

/*
 *
 * ----------------------------------------------- */
$(function () {
  $('img[usemap="#image-map"]').mapster({
    fillOpacity: 1,
    singleSelect: true,
    altImage:
      "../../wp-content/themes/scsk_sys_cms/img/netskope/realization/active-1.png",
    altImageFill: false,
    altImageStroke: false,
    clickNavigate: true,
  });

  $("area[selected]").mapster("select");
});
