import "jquery-smooth-scroll";
import "jquery.easing";
import { isMobile } from "./isMobile";

/*
 *
 * ----------------------------------------------- */
(function () {
  if (typeof $.smoothScroll !== "function") {
    return false;
  }

  var reSmooth = /^#sm-/;
  var id;

  $(window).on("load", function () {
    if (reSmooth.test(location.hash)) {
      id = "#" + location.hash.replace(reSmooth, "");

      var offset = isMobile() ? -65 : -109;

      $.smoothScroll({
        scrollTarget: id,
        offset: offset,
        easing: "easeInOutCubic",
      });
    }
  });
})();

/*
 *
 * ----------------------------------------------- */
// jQuery Smooth Scroll - v2.2.0 - 2017-05-05
// https://github.com/kswedberg/jquery-smooth-scroll
jQuery(function ($) {
  $("[data-sm]").smoothScroll({
    offset: isMobile() ? -65 : -109,
  });
});
